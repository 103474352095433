import React from 'react';
import {Image, Modal, StyleSheet, View} from 'react-native'; //Text
import theme from '@style';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {goTo} from '@utils';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

const sampleImage = require('@assets/icons/home/first-recharge.webp'); // 弹出图片路径

interface HomePopTwoProps {
  isImageVisible: boolean;
  setIsImageVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomePopTwo: React.FC<HomePopTwoProps> = ({
  isImageVisible,
  setIsImageVisible,
}) => {
  const {screenWidth} = useScreenSize();
  // Modal关闭
  const closeImage = () => {
    setIsImageVisible(false);
  };
  // 点击图片
  const onImage = () => {
    closeImage();
    goTo('Recharge');
  };

  return (
    <Modal
      transparent={true}
      visible={isImageVisible}
      onRequestClose={closeImage}
      animationType="fade">
      <View style={styles.modalOverlay}>
        <NativeTouchableOpacity
          onPress={onImage}
          style={[{marginBottom: screenWidth * 0.13}]}>
          <LazyImage
            imageUrl={sampleImage}
            width={screenWidth * 0.85}
            height={screenWidth * 0.98}
          />
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          onPress={closeImage}
          style={[styles.closeButton, {marginBottom: screenWidth * 0.08}]}>
          <Image
            style={[theme.icon.xxl]}
            source={require('@assets/icons/home/button-close.png')}
          />
        </NativeTouchableOpacity>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    backgroundColor: 'none',
  },
});

export default HomePopTwo;
