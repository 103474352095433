import {useCardModal} from '@businessComponents/modal';

import {LayoutChangeEvent, View, StyleSheet} from 'react-native';
import theme from '@style';
import LazyImage from '@basicComponents/image';
import React from 'react';
import Text from '@basicComponents/text';
import {goTo, toPriceStr} from '@utils';
import globalStore from '@/services/global.state';
import {IUserInfo} from '@services/global.service';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {
  rechargeIcon,
  withdrawIcon,
} from '@/common-pages/wallet/wallet.variable';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
const refreshIcon = require('@assets/icons/refresh.webp');
export interface MeAmountProps {
  login?: boolean;
  onLayout?: (e: LayoutChangeEvent) => void;
  topBlockWidth?: number;
  user?: IUserInfo;
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onRefresh?: () => void;
}

const MeAmount: React.FC<MeAmountProps> = ({
  login,
  onLayout,
  user,
  onRecharge,
  onWithdraw,
  onRefresh,
}) => {
  const {i18n} = useTranslation();
  const {renderModal, show} = useCardModal();
  const {screenWidth} = useScreenSize();

  const showRechargeModal = () => {
    show({
      icon: rechargeIcon,
      title: i18n.t('me.tip.cashTitle'),
      content: i18n.t('me.tip.cashContent'),
    });
  };

  const showDrawModal = () => {
    show({
      icon: withdrawIcon,
      title: i18n.t('me.tip.withdrawTitle'),
      content: i18n.t('me.tip.withdrawContent'),
    });
  };

  const showBonusModal = () => {
    show({
      icon: rechargeIcon,
      title: i18n.t('me.tip.bonusTitle'),
      content: i18n.t('me.tip.bonusContent'),
    });
  };

  return (
    <View onLayout={onLayout}>
      <View
        style={[
          styles.wallet,
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.padding.lrxxl,
        ]}>
        <NativeTouchableOpacity onPress={() => goTo('Recharge')}>
          <View style={[theme.flex.col]}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text style={[theme.font.fs, theme.font.brown]}>
                {i18n.t('me.money.totalWallet')}
                {'  ▸'}
              </Text>
            </View>
            <Text
              fontFamily="fontDin"
              blod
              style={[theme.font.fxl, theme.font.brown, theme.margin.topxxs]}>
              {login
                ? toPriceStr(user?.userBalance || 0, {
                    thousands: true,
                    spacing: true,
                    currency: globalStore.currency,
                  })
                : '********'}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[
            theme.flex.flex1,
            {position: 'absolute', top: 0, left: screenWidth * 0.453},
          ]}
          activeOpacity={1}
          onPress={onRecharge}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Text style={[theme.font.brown]} fontSize={theme.fontSize.s}>
              {i18n.t('me.money.bonus')}
            </Text>
            {/* TODO 这里会引起报错,button不能是另一个botton的子元素 */}
            <NativeTouchableOpacity
              activeOpacity={1}
              style={[
                theme.margin.lefts,
                theme.flex.center,
                {
                  borderColor: theme.basicColor.brown,
                  height: 16,
                  width: 16,
                  borderRadius: 8,
                  borderWidth: 2,
                },
              ]}
              onPress={showBonusModal}>
              <Text fontSize={10} blod color={theme.fontColor.brown}>
                !
              </Text>
            </NativeTouchableOpacity>
          </View>
          <Text
            fontFamily="fontDin"
            blod
            style={[theme.font.fxl, theme.font.brown, theme.margin.topxxs]}>
            {login
              ? toPriceStr(user?.userGameBalance || 0, {
                  thousands: true,
                  spacing: true,
                })
              : '******'}
          </Text>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity onPress={onRefresh}>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={refreshIcon}
            width={theme.iconSize.xxl}
            height={theme.iconSize.xxl}
          />
        </NativeTouchableOpacity>
      </View>
      <View style={[theme.margin.topxxl]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.padding.lrxxl,
            theme.padding.tbs,
          ]}>
          <NativeTouchableOpacity
            style={[theme.flex.flex1]}
            activeOpacity={1}
            onPress={onRecharge}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text style={[theme.font.brown]} fontSize={theme.fontSize.s}>
                {i18n.t('me.money.cashBalance')}
              </Text>
              {/* TODO 这里会引起报错,button不能是另一个botton的子元素 */}
              <NativeTouchableOpacity
                activeOpacity={1}
                style={[
                  theme.margin.lefts,
                  theme.flex.center,
                  {
                    borderColor: theme.basicColor.brown,
                    height: 16,
                    width: 16,
                    borderRadius: 8,
                    borderWidth: 2,
                  },
                ]}
                onPress={showRechargeModal}>
                <Text fontSize={10} blod color={theme.fontColor.brown}>
                  !
                </Text>
              </NativeTouchableOpacity>
            </View>
            <Text
              fontFamily="fontDin"
              blod
              style={[theme.font.fxl, theme.font.brown, theme.margin.topxxs]}>
              {login
                ? toPriceStr(user?.rechargeAmount || 0, {
                    thousands: true,
                    spacing: true,
                  })
                : '******'}
            </Text>
          </NativeTouchableOpacity>
          <NativeTouchableOpacity
            style={[theme.flex.flex1]}
            activeOpacity={1}
            onPress={onWithdraw}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text style={[theme.font.brown]} fontSize={theme.fontSize.s}>
                {i18n.t('me.money.withdrawAmount')}
              </Text>
              <NativeTouchableOpacity
                activeOpacity={1}
                style={[
                  theme.margin.lefts,
                  theme.flex.center,
                  {
                    borderColor: theme.basicColor.brown,
                    height: 16,
                    width: 16,
                    borderRadius: 8,
                    borderWidth: 2,
                  },
                ]}
                onPress={showDrawModal}>
                <Text fontSize={10} blod color={theme.fontColor.brown}>
                  !
                </Text>
              </NativeTouchableOpacity>
            </View>
            <Text
              fontFamily="fontDin"
              blod
              style={[theme.font.fxl, theme.font.brown, theme.margin.topxxs]}>
              {login
                ? toPriceStr(user?.canWithdrawAmount || 0, {
                    thousands: true,
                    spacing: true,
                  })
                : '******'}
            </Text>
          </NativeTouchableOpacity>
        </View>
      </View>
      {renderModal}
    </View>
  );
};

const styles = StyleSheet.create({
  wallet: {},
});

export default MeAmount;
