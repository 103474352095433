import theme from '@style';
import {homeServiceStyle} from '../home.style';
import LazyImage from '@basicComponents/image';
import React, {useState} from 'react';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {View} from 'react-native';
import {goTo} from '@/utils';
import HomePopTwo from './home-pop-two';
import globalStore from '@/services/global.state';
import {goCS} from '@/utils';
const HomeService = ({spinShow}: {spinShow: () => void}) => {
  const [isImageVisible, setIsImageVisible] = useState(false);

  const toggleModal = () => {
    setIsImageVisible(!isImageVisible);
  };

  return (
    <View
      style={[theme.position.abs, homeServiceStyle.service, theme.flex.center]}>
      <TouchableOpacity
        onPress={() => {
          if (!globalStore.token) {
            goTo('Login');
            return;
          }
          goTo('CheckIn');
        }}>
        <LazyImage
          width={70}
          height={70}
          imageUrl={require('@assets/gif/daily-tasks.gif')}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          spinShow();
        }}>
        <LazyImage
          width={70}
          height={70}
          imageUrl={require('@assets/gif/luckspin.gif')}
        />
      </TouchableOpacity>
      {(globalStore.userInfo?.totalRechargeAmount === 0 ||
        !globalStore.token) && (
        <TouchableOpacity
          onPress={() => {
            if (!globalStore.token) {
              goTo('Login');
              return;
            }
            toggleModal();
          }}>
          <LazyImage
            width={70}
            height={70}
            imageUrl={require('@assets/gif/first-recharge.gif')}
          />
        </TouchableOpacity>
      )}
      <TouchableOpacity onPress={goCS}>
        <LazyImage
          width={55}
          height={55}
          // radius={60}
          imageUrl={require('@components/assets/icons/service.webp')}
        />
      </TouchableOpacity>

      <HomePopTwo
        isImageVisible={isImageVisible}
        setIsImageVisible={setIsImageVisible}
      />
    </View>
  );
};

export default HomeService;
